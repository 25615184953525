import React, {useEffect} from 'react';
import {Attention, Button} from '@fabric-ds/react';
import {useRef} from 'react';
import classes from './SoldState.module.css';
import {trackMeglerprofilButton} from "../tracking";

export type MeglerProfilProps = {
    url: string;
    adId: number;
    orgId: number;
    className?: string;
};

export function MeglerprofilButton(meglerprofilProps: MeglerProfilProps) {

    function setQueryParam(url: string, key: string, value: string) {
        const urlObj = new URL(url, window.location.origin);
        urlObj.searchParams.append(key, value);
        return urlObj.toString();
    }
    
    const meglerprofilRef = useRef(null);
        useEffect(() => {
            let viewTracked = false;
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !viewTracked) {
                        trackMeglerprofilButton(
                            'View',
                            meglerprofilProps.url,
                            meglerprofilProps.adId.toString(),
                            meglerprofilProps.orgId.toString(),
                        );
                        viewTracked = true;
                    }
                });
            });
            if (meglerprofilRef.current) {
                observer.observe(meglerprofilRef.current);
            }
            return () => {
                if (meglerprofilRef.current) {
                    observer.unobserve(meglerprofilRef.current);
                }
            };
        }, []);
    return (

        <div className={meglerprofilProps.className}>
            <div className="flex my-auto">
                <Button
                    ref={meglerprofilRef}
                    small
                    onClick={async (event) => {
                        event.preventDefault();
                        trackMeglerprofilButton(
                            'Click',
                            meglerprofilProps.url,
                            meglerprofilProps.adId.toString(),
                            meglerprofilProps.orgId.toString(),
                        );
                        window.open(
                            meglerprofilProps.adId
                                ? setQueryParam(
                                    meglerprofilProps.url,
                                    'finnkode',
                                    meglerprofilProps.adId.toString()
                                )
                                : meglerprofilProps.url,
                            '_blank',
                        );
                    }}
                >
                    <div className={'my-0 mx-2'}>Meglerprofil</div>
                </Button>
            </div>
            <Attention placement={"right"} callout={true} isShowing={true} className={classes.showAttention}>
                <div style={{
                    marginTop: '-2px',
                    marginBottom: '-2px',
                }}
                     className={'text-primary text-12 px-0 py-0'}>
                    Nyhet!
                </div>
            </Attention>
        </div>
    );
}
