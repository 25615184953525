import React, { CSSProperties, useState } from 'react';
import { toast } from '@fabric-ds/elements/toast';
import { Button } from '@fabric-ds/react';
import useSessionStorage from '../hooks';
import type {
    AgentProfileLink,
    BasicProfile,
    Contact,
    SoldStateData,
} from '../types';
import Form from './Form';
import HalfMoonButton from './HalfMoonButton';
import Profile from './Profile';
import ProfileExpanded from './ProfileExpanded';
import classes from './SoldState.module.css';
import { Event, track } from '../tracking';

type SoldStatePageholderProps = {
    children: React.ReactNode;
    className?: string;
    style?: CSSProperties;
};

const SoldStatePageholder = (props: SoldStatePageholderProps) => {
    const { children, className = '', style } = props;
    return (
        <div style={style}>
            <div className={`page-container bg-transparent ${className}`}>
                {children}
            </div>
        </div>
    );
};

type SoldStateProps = {
    adId: number;
    basicProfile: BasicProfile;
    agentProfileData?: AgentProfileLink[];
    soldState: SoldStateData;
    trackingSubvertical?: string;
};

function SoldState(props: SoldStateProps) {
    const {
        adId,
        basicProfile,
        agentProfileData,
        soldState,
        trackingSubvertical,
    } = props;
    const soldStateQuestion = soldState.questions.map(({ name, value }) => ({
        value: name,
        label: value,
    }));
    const broker: Contact = basicProfile.contacts[0]!;

    const [isFormOpen, setFormOpen] = useSessionStorage<boolean>(
        `__soldState[${adId}]_open`,
        false,
    );

    const [isFormSentSuccessfully, setFormSentSuccessfully] =
        useState<boolean>(false);

    const maxNumProfileLinks = 2;

    const headingId = 'sold-heading';
    const halfMoonId = 'sold-half-moon-button-id';

    const focusOn = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.focus();
        }
    };

    function toggleForm(open: boolean, arrow: boolean = false) {
        setFormOpen(open);
        if (open && !isFormSentSuccessfully) {
            focusOn(headingId);
            track({
                eventType: Event.AskButtonOrArrow,
                orgName: basicProfile.orgName,
                trackingSubvertical,
                pageName: false,
                objectTypeP: arrow && 'Arrow',
            })();
        } else {
            focusOn(halfMoonId);
        }
    }

    const onFormSentSuccessfully = () => {
        setFormSentSuccessfully(true);
        setFormOpen(false);

        focusOn(headingId);
    };

    const sixtyPercentHexOpacity = `99`;
    const [logoAlignment, logoDividerColor] =
        soldState.heading.backgroundColor ===
        soldState.heading.logoBackgroundColor
            ? [
                  'justify-right pr-24',
                  `${soldState.ctaButton.textColor}${sixtyPercentHexOpacity}`,
              ]
            : ['justify-center px-24', soldState.heading.logoBackgroundColor];

    const rootStyles = {
        '--sold-state-bgcolor': soldState.backgroundColor,
        '--sold-state-textcolor': soldState.textColor,
        '--sold-state-client-bgcolor': soldState.ctaButton.backgroundColor,
        '--sold-state-client-textcolor': soldState.ctaButton.textColor,
        '--sold-state-buttoncolor': soldState.ctaButton.backgroundColor,
        '--sold-state-button-bordercolor': soldState.ctaButton.borderColor,
        '--sold-state-buttoncolor-hover':
            soldState.ctaButton.backgroundHoverColor,
        '--sold-state-buttoncolor-active':
            soldState.ctaButton.backgroundActiveColor,
        '--sold-state-buttontext-color': soldState.ctaButton.textColor,
        '--sold-state-buttontext-color-hover':
            soldState.ctaButton.textHoverColor,
        '--sold-state-buttontext-color-active':
            soldState.ctaButton.textHoverColor,
        '--sold-state-secondary-button-color':
            soldState.profileBox.actionButton.backgroundColor,
        '--sold-state-secondary-button-color-hover':
            soldState.profileBox.actionButton.backgroundHoverColor,
        '--sold-state-secondary-button-color-active':
            soldState.profileBox.actionButton.backgroundActiveColor,
        '--sold-state-secondary-button-border-color':
            soldState.profileBox.actionButton.borderColor,
        '--sold-state-secondary-text-color':
            soldState.profileBox.actionButton.textColor,
        '--sold-state-secondary-text-hover-color':
            soldState.profileBox.actionButton.textHoverColor,
        '--sold-state-buttontextcolor': soldState.ctaButton.textColor,
        '--sold-state-logo-bgcolor': soldState.heading.logoBackgroundColor,
        '--sold-state-logo-divider-color': logoDividerColor,
    } as React.CSSProperties;

    const { orgName } = basicProfile;
    const objectPosition = orgName.toUpperCase().startsWith('DNB')
        ? 'object-right'
        : 'object-top';
    return (
        <section
            id="sold-state"
            className={`${classes['sold-state']} mb-64  ${
                isFormOpen ? classes['sold-state--open'] : ''
            }`}
            aria-label="Solgt, still spørsmål til megler"
            style={rootStyles}
        >
            <SoldStatePageholder
                className="page-container bg-transparent flex"
                style={{
                    backgroundColor: 'var(--sold-state-client-bgcolor)',
                    color: 'var(--sold-state-client-textcolor)',
                }}
            >
                <div
                    className={`${classes['sold-state__logo']} inline-flex items-center ${logoAlignment} py-16 mb-0`}
                >
                    <img src={basicProfile.logo} alt={`Logo for ${orgName}.`} />
                </div>
            </SoldStatePageholder>
            <SoldStatePageholder
                className="flex flex-col"
                style={{
                    backgroundColor: 'var(--sold-state-bgcolor)',
                    color: 'var(--sold-state-textcolor)',
                }}
            >
                <div className={`${classes['sold-state__layout']} mb-16 mt-48`}>
                    <div className={`${classes['sold-state__heading']} mb-32`}>
                        {isFormSentSuccessfully ? (
                            <>
                                <h2 id={headingId} tabIndex={-1}>
                                    Skjemaet er sendt!
                                </h2>
                                <p className="pt-16">
                                    Megler svarer på din henvendelse så raskt
                                    som mulig.
                                </p>
                            </>
                        ) : (
                            <h2
                                id={headingId}
                                tabIndex={-1}
                                className={'outline-none'}
                                style={{ hyphens: 'manual' }}
                            >
                                Har du noen spørsmål rundt salget av denne
                                eiendommen?
                            </h2>
                        )}
                    </div>
                    {!isFormSentSuccessfully && isFormOpen && (
                        <div className={classes['sold-state__form']}>
                            <Form
                                adId={adId}
                                formSuccessCallback={onFormSentSuccessfully}
                                definedQuestions={soldStateQuestion}
                                toast={toast}
                            />
                            <button
                                className="sr-only"
                                onClick={() => toggleForm(false)}
                            >
                                Lukk skjema
                            </button>
                        </div>
                    )}
                    <div className={classes['sold-state__profile']}>
                        {broker && (
                            <Profile
                                adId={adId}
                                imageUrl={broker.image}
                                name={broker.name}
                                agentProfileData={agentProfileData}
                                phones={broker.phones}
                                title={broker.title}
                                orgName={orgName}
                                orgId={basicProfile.orgId}
                                objectPosition={objectPosition}
                                trackingSubvertical={trackingSubvertical}
                            />
                        )}
                        {isFormOpen && (
                            <ProfileExpanded
                                orgName={orgName}
                                logoUrl={basicProfile.logo}
                                slogan={soldState.profileBox.slogan}
                                homepageUrl={basicProfile.homepageUrl}
                                moreAdsFromLink={basicProfile.moreFromLink}
                                customLinks={(
                                    soldState.profileLinks ||
                                    soldState.profileBox?.profileLinks ||
                                    []
                                ).slice(0, maxNumProfileLinks)}
                                actionLink={
                                    soldState.actionLink ||
                                    soldState.profileBox?.actionLink
                                }
                                trackingSubvertical={trackingSubvertical}
                            />
                        )}
                    </div>
                    {!isFormSentSuccessfully && !isFormOpen && (
                        <div className={classes['sold-state__cta']}>
                            <Button
                                primary
                                id="ctaButtonA"
                                onClick={() => toggleForm(true)}
                            >
                                Still spørsmål til megler
                            </Button>
                        </div>
                    )}
                </div>
                {!isFormSentSuccessfully && (
                    <div className={classes['sold-state__toggle']}>
                        <HalfMoonButton
                            id={halfMoonId}
                            isOpen={isFormOpen}
                            toggleForm={toggleForm}
                        />
                    </div>
                )}
            </SoldStatePageholder>
        </section>
    );
}

export default SoldState;
